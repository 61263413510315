import { Inject, Injectable } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  dropSetting = {
    singleSelection: false,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  keyForTaxi = [
    { displayeName: "taxi", moduleName: "taxi" },
    { displayeName: "shuttle", moduleName: "taxi" },
  ];
  keyForOndemand = [
    { displayName: "Grocery", moduleName: "groceryApp" },
    { displayName: "Food", moduleName: "foodDeliveryApp" },
    { displayName: "E-commerce ", moduleName: "ecommerce" },
    { displayName: "Home Service", moduleName: "homeservice" },
  ];

  baseUrl;
  imageUrl;

  profileData$ = new Subject<string>();
  slotTime$ = new Subject<any>();
  appSettings$ = new Subject<string>();
  pendingOrderCount$ = new Subject<number>();
  isGeofenceAvailable$ = new Subject<string>();
  geofenceChnaged$ = new Subject<string>();
  // templateById$ = new Subject<string>();

  // Socket Url
  socketUrl = "https://taxiapi.xeat.lk";
  appBaseUrl = "https://";


  clientUrl: string;
  projectSettings: any;
  appSettings: any;

  constructor(
    private toaster: ToastrManager,
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title
  ) {
    this.getCountryCode();
  }
  getBaseUrl() {
    // this.baseUrl = "https://appgrowthcompany.com:";
    this.baseUrl = "https://";

    return this.baseUrl;
  }
  getClientBaseUrl() {
    this.clientUrl = "https://taxiapi.xeat.lk";
  }

  deleteExistingGeofence() {
    //Deleting existing geofence id when selected in another module
    if (localStorage.getItem("geoId")) {
      localStorage.removeItem("geoId");
      localStorage.removeItem("zoneId");
    }
  }
  checkPermission(name) {
    var admin = JSON.parse(localStorage.getItem("admin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.name == name && element.childs.length == 1) {
        data = {
          isView: element.childs[0].view,
          isEdit: element.childs[0].edit,
          isDelete: element.childs[0].delete,
        };
      }
    });
    return data;
  }

  getGreatSettings() {
    return this.http
      .get(this.appBaseUrl + "commonapi.xeat.lk/v2/admin/greatSetting")
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.projectSettings = resp["data"];
          localStorage.setItem(
            "greatSettings",
            JSON.stringify(this.projectSettings)
          );
        }
      });
  }
  getforTaxi() {
    return this.keyForTaxi;
  }

  getAppSettings() {
    return this.http
      .get(this.appBaseUrl + "commonapi.xeat.lk/v2/admin/getAppSetting")
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.appSettings = resp["data"];
          localStorage.setItem("appSettings", JSON.stringify(this.appSettings));
          this.setInitialSetup();
        }
      });
  }

  GreatSettings() {
    return this.http.get(this.appBaseUrl + "commonapi.xeat.lk/v2/admin/greatSetting");
  }

  AppSettings() {
    return this.http.get(this.appBaseUrl + "commonapi.xeat.lk/v2/admin/getAppSetting");
  }

  setInitialSetup() {
    // Set On localstorage for global access
    // localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color
    if (this.appSettings && this.appSettings.colorCodes) {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.appSettings.colorCodes
      );
    }
    // Set Favicon
    if (this.appSettings && this.appSettings.favIcon) {
      this._document
        .getElementById("appFavicon")
        .setAttribute("href", this.appSettings.favIcon);
    }
    // Set Title
    if (this.appSettings && this.appSettings.appName) {
      this.titleService.setTitle(this.appSettings.appName + " - Admin Panel");
    }
  }

  getModuleFromModuleKey(moduleKey) {
    return this.getGreatSettings()["appType"].find(
      (element) => element.moduleKey == moduleKey
    );
  }

  getmoduleNameByKey(moduleKey) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    console.log(greatSettings, "greatSettings");

    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        moduleName = element.moduleName;
      }
    });
    return moduleName;
  }

  getVerticalTypeByModuleKey(moduleKey) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        moduleName = element.moduleName;
      }
    });
    let verticalType;
    if (
      moduleName == "foodDeliveryApp" ||
      moduleName == "groceryApp" ||
      moduleName == "ecommerce" ||
      moduleName == "pharmacy" ||
      moduleName == "homeservice"
    ) {
      verticalType = 1;
    } else if (moduleName == "taxi" || moduleName == "basicDeliveryApp") {
      verticalType = 2;
    } else if (moduleName == "shuttle") {
      verticalType = 3;
    }
    return verticalType;
  }

  checkPermissionwithchildren(moduleKey, name) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        (displayName = element.displayName), (moduleName = element.moduleName);
      }
    });
    var admin = JSON.parse(localStorage.getItem("admin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.parent == true) {
        if (element.name == displayName) {
          if (moduleName == "foodDeliveryApp" && name == "Merchant Types") {
            name = "Cuisine Types";
          } else if (moduleName == "foodDeliveryApp" && name == "Categories") {
            name = "Food categories";
          } else if (
            moduleName == "foodDeliveryApp" &&
            name == "Manage Merchants"
          ) {
            if (greatSettings.appType.vendorType == "single")
              name = "Manage Cuisine Products";
            else {
              name = "Manage Restaurants";
            }
          }

          var childs = element.childs;
          childs.forEach((ele) => {
            if (ele.childName == name) {
              data = {
                isView: ele.view,
                isEdit: ele.edit,
                isDelete: ele.delete,
              };
            }
          });
        }
      }
    });
    return data;
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }

  successToast(message) {
    this.toaster.successToastr(message, "", {
      maxShown: 1,
      toastTimeout: 5000,
    });
  }
  errorToast(message) {
    this.toaster.errorToastr(message);
  }

  getFromLocalStorage(varName) {
    return JSON?.parse(localStorage.getItem(varName));
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.appSettings$.next("added");
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.appSettings$.next("removed");
  }

  confirmToast(text) {
    return Swal.fire({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  confirmToastWithTittle(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  confirmToastDynamicTitleTextIcon(title, text, icon) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
  }

  successDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      timer: 1000,
      icon: "success",
      showConfirmButton: false,
    }).then();
  }

  warningDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      timer: 1000,
      icon: "warning",
      showConfirmButton: false,
    }).then();
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appId", greatSettings["appId"]);
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    this.router.navigateByUrl("/auth/login");
  }
}
