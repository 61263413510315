<h2 mat-dialog-title>Pay Merchant</h2>
<mat-dialog-content>
  <form [formGroup]="paymentForm">
    <div class="usr_profile_inrmain">
      <div class="col-12">
        <div class="row">
          <div class="col-12 slect_ctgopt">
            <div class="usr_profile_biodata">
              <label>Amount</label>
              <div class="input-group">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  placeholder="Enter the Amount"
                  formControlName="amount"
                  appNumberOnly
                />
              </div>
              <div
                *ngIf="submitted && paymentForm.controls.amount.errors"
                class="invalid-feedback"
              >
                <div *ngIf="paymentForm.controls.amount.errors.required">
                  Amount is required.
                </div>
                <div *ngIf="paymentForm.controls.amount.errors.min">
                  Please enter amount greater than Zero(0)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="col-12 btm-btn">
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-primary mr-2 common-btn" (click)="onSubmit()">
        Submit
      </button>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>
