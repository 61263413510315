<h2 mat-dialog-title>Payment History</h2>
<mat-dialog-content>
  <div class="table-wrap">
    <table class="table border_spacing border_pd">
      <thead>
        <tr>
          <th>#</th>
          <th>Pay Amount</th>
          <th>Pay Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of paymentHistory | paginate: config1; let i = index"
        >
          <td>{{ (currentPage1 - 1) * 10 + i + 1 }}</td>
          <td>{{ appSettings.currency }} {{ item?.payAmount }}</td>
          <td>{{ item?.payDate | date }}</td>
        </tr>
      </tbody>
    </table>

    <h4 *ngIf="paymentHistory && paymentHistory.length == 0">
      <app-no-data-found></app-no-data-found>
    </h4>
  </div>
</mat-dialog-content>
<div class="col-12 btm-btn">
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>
