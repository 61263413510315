<h2 mat-dialog-title *ngIf="item">Change Document</h2>
<mat-dialog-content>
  <div class="usr_profile_inrmain">
    <div class="row">
      <div class="col-12">
        <div [class.imageAdded]="categoryImage" class="usr_profile_img">
          <div class="usr_profile_imgbx">
            <div class="user_img">
              <figure *ngIf="categoryImage">
                <img style="height: 100px; width: 100px" [src]="categoryImage" />
              </figure>
            </div>
          </div>
          <div class="user_profile_edit">
            <div class="up_file edit_padd">
              <img src="assets/img/default-user.png" alt="choose_img" />
              <span>Upload File</span>
              <input type="file" accept="image/*" (change)="fileHandler($event)" />
            </div>
          </div>
          <div *ngIf="expiryDate">
            <div>Expiry Date</div>
            <div>
              <input [min]="today" type="date" [(ngModel)]="expiryDate" (change)="dateChange($event)" />
            </div>
          </div>

          <div *ngIf="submitted && !categoryImage" class="invalid-feedback">
            Document is required.
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-primary common-btn mr-2" *ngIf="item" (click)="onUpdate()">
        Update
      </button>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</mat-dialog-actions>