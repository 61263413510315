import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "src/app/core/services/common.service";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";

@Component({
  selector: "app-add-language",
  templateUrl: "./add-language.component.html",
  styleUrls: ["./add-language.component.scss"],
})
export class AddLanguageComponent implements OnInit {
  mainForm: FormGroup;
  File: any;
  Image;
  item;
  submitted = false;
  constructor(
    private dialog: MatDialogRef<AddLanguageComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public api: HttpService,
    public coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.mainForm = this.formBuilder.group({
      languageName: new FormControl("", [Validators.required]),
    });
  }

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    let res = await this.api
      .post(this.coreUrlList.uploadFile, formData)
      .toPromise();

    return res["data"];
  }

  async fileHandler(event) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.Image = event.target.result;
        };
      }
      this.File = await this.uploadImage(File);
    } else {
      this.comm.errorToast("Only PNG, JPEG ,JPG are Allow ");
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.mainForm.valid) {
      let data = this.mainForm.value;
      if (this.File) {
        data.image = this.File;
      }
      let modules = [
        "ADMIN",
        "MERCHANT",
        "DRIVERAPP",
        "CUSTOMERAPP",
        "BACKEND",
      ];
      modules.forEach((item) => {
        data.languageFor = item;
        this.api.post(this.coreUrlList.addLanguage, data).subscribe((res) => {
          if (res["status"] == 200) {
            // this.comm.successToast(res["message"]);
            this.dialog.close();
          }
        });
      });
    }
  }

  close() {}
}
