<h2 mat-dialog-title *ngIf="item">Edit Language</h2>
<h2 mat-dialog-title *ngIf="!item">Add Language</h2>
<mat-dialog-content>
  <form [formGroup]="mainForm">
    <div class="usr_profile_inrmain">
      <div *ngIf="!item" class="col-12">
        <div class="row">
          <div class="col-12 slect_ctgopt">
            <div class="usr_profile_biodata"></div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="usr_profile_biodata">
              <label>Language Name</label>
              <div class="input-group">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  formControlName="languageName"
                  placeholder="Enter Language Name"
                  appNoSpaceAtFirst
                />
              </div>
              <div
                *ngIf="submitted && mainForm.controls.languageName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="mainForm.controls.languageName.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div [class.imageAdded]="Image" class="usr_profile_img">
          <div class="usr_profile_imgbx new_spc">
            <label>Image</label>
            <div class="user_img">
              <figure *ngIf="Image">
                <img [src]="Image" />
              </figure>
            </div>
          </div>
          <div class="user_profile_edit">
            <div class="up_file">
              <img src="assets/img/default-user.png" alt="choose_img" />
              <span>Upload File</span>
              <input type="file" accept="image/*" (change)="fileHandler($event)" />
            </div>
          </div>
          <div *ngIf="submitted && !categoryImage" class="invalid-feedback">
            Image is required.
          </div>
        </div>
      </div> -->

      <div class="form-group col-md-12">
        <div class="outer_uploadsshdf bigholderhor default_viewer">
          <p class="heading-setting-form">Image</p>

          <div class="image_placeholder">
            <figure *ngIf="Image">
              <img [src]="Image" />
            </figure>
          </div>

          <div class="image_placeholder_showup_file">
            <div class="dftlp">
              <img src="assets/img/upload.png" alt="choose_img" />
              <span>Upload File</span>
            </div>
            <input
              type="file"
              accept="image/*"
              (change)="fileHandler($event)"
            />
          </div>

          <div class="invalid-feedback">Image is required.</div>
          <div class="invalid-feedback">Only PNG, JPEG ,JPG are Allow</div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="col-12 btm-btn">
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-primary mr-2 common-btn" (click)="onSubmit()">
        Add
      </button>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>
