import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {
  appSettings: any;
  constructor(private spinner: NgxSpinnerService) {
    // this.appSettings = JSON.parse(localStorage.getItem("appSettings"));
    // console.log('this.appSettings', this.appSettings.appSecretKey);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let fullUrl = window.location.href;
    let appId = "bjfvojvgfkf48sqdmfu0wflb";

    if (req.url) {
      if (!req.url.includes("driver/v1")) {
        let token = "NA";
        let geofenceId = "NA";
        let timezone = "NA";
        let zoneId = "NA";

        if (localStorage.getItem("token")) {
          token = localStorage.getItem("token");
        }

        if (localStorage.getItem("geofenceId")) {
          geofenceId = localStorage.getItem("geofenceId");
        }
        if (localStorage.getItem("geofence")) {
          zoneId = JSON.parse(localStorage.getItem("geofence"))["zoneId"];
        }
        if (localStorage.getItem("Timezone")) {
          timezone = localStorage.getItem("Timezone");
        }

        let authReq;
        authReq = req.clone({
          headers: new HttpHeaders({
            Authorization: token,
            geofenceid: geofenceId,
            timeZone: timezone,
            appid: appId,
            zoneId: zoneId,
          }),
        });

        if (
          (req.method.toLowerCase() == "post" ||
            req.method.toLowerCase() == "put") &&
          req.body instanceof FormData
        ) {
          authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: token != "NA" ? `${token}` : "",
              geofenceid: geofenceId,
              appid: appId,
            }),
          });
        }
        return authReq;
      } else {
        let token = "NA";
        if (localStorage.getItem("token")) {
          token = localStorage.getItem("token");
        }

        let authReq;
        authReq = req.clone({
          headers: new HttpHeaders({
            Authorization: token != "NA" ? token : "",
            appid: appId,
          }),
        });
        return authReq;
      }
    }
    return req;
  }
}
