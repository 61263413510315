export class coreUrlList {
  comm = "commonapi.xeat.lk/v2";
  onDemand = "taxiapi.xeat.lk/v2";
  clientBaseUrl = "";

  // File Upload
  uploadFile: string = `${this.comm}/upload/upload`;

  //CountryCode
  // countryCode: string = `/assets/json/countryCode.json`;
  //  App Setting
  appSetting: string = `${this.comm}/admin/appSetting`;
  getAppSetting: string = `${this.comm}/admin/getAppSetting`;
  greatSettings = `${this.comm}/admin/greatSetting`;

  // Auth
  login: string = `${this.comm}/admin/login`;
  logout: string = `${this.comm}/admin/logout`;
  forgotPasswordSendEmail1: string = `${this.comm}/admin/forgotPassword`;
  checkToken: string = `${this.comm}/admin/panel/checkToken`;
  resetPassword: string = `${this.comm}/admin/panel/resetpass`;
  signup = `${this.clientBaseUrl}/v/client/createClient`;
  setUpBussiness = `${this.clientBaseUrl}/v1/client/updateClient`;
  setUpStore = `${this.clientBaseUrl}/v1/client/updateClient`;
  getModules = `${this.clientBaseUrl}/v1/admin/getModule`;
  loginClient = `${this.clientBaseUrl}/v1/client/loginClient`;
  getClientDetailsByAppId = `${this.clientBaseUrl}/v1/client/getClientDetailsByAppId`;
  checkForgotPassword: string = `${this.comm}/admin/checkForgotPassword`;
  resetpassword: string = `${this.comm}/admin/resetpassword`;

  // Payment SMS Methods
  getPaymentMethods: string = `${this.onDemand}/admin/getPaymentMethods`;
  getPaymentMode: string = `${this.onDemand}/admin/getPaymentModes`;
  getSmsMethods: string = `${this.onDemand}/admin/getSmsMethods`;
  managePaymentMethods: string = `${this.onDemand}/admin/managePaymentMethods`;
  manageSmsMethods: string = `${this.onDemand}/admin/manageSmsMethods`;
  managefeatureoff: string = `${this.onDemand}/admin/manageFeatureOff`;
  user:string = `${this.onDemand}/admin/user`
  userWithOutPagination = `${this.onDemand}/admin/userWithoutPagination`
  booking:string = `${this.onDemand}/admin/driver/createBooking`
 paymentLink:string = `${this.onDemand}/admin/driver/sendPaymentLink`
  // Admin
  getAdminProfile: string = `${this.comm}/admin/getAdminProfile`;
  updateProfile: string = `${this.comm}/admin/updateProfile`;
  changePassword: string = `${this.comm}/admin/adminChangePass`;

  // User
  getUserAllOrders: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  redeemUser: string = `${this.onDemand}/store/getredeemoptions`;
  getAllUsersCSV: string = `${this.onDemand}/admin/getAllUsersCSV`;
  userDocument: string = `${this.onDemand}/admin/userDocument`;
  getUserOrderCount: string = `${this.onDemand}/admin/getUserOrderCount`;
  updateUserStatus: string = `${this.onDemand}/admin/blockUnblockUser`;
  getUserById: string = `${this.onDemand}/admin/user`;
  bulkUploadUser: string = `${this.onDemand}/admin/bulkUploadUser`;
  getAllUserExport: string = `${this.onDemand}/admin/getAllUserExport`;

  // Dashboard
  dashboard: string = `${this.comm}/admin/dashboard`;
  recentBooking: string = `${this.onDemand}/admin/recentBooking`;

  // Zone
  getZones: string = `${this.onDemand}/zone/getZones`;

  //Geofence
  getGeofence: string = `${this.onDemand}/zone/geofence`;
  addGeofence: string = `${this.onDemand}//zone/addGeofence`;

  // CMS - Faq
  addFaq: string = `${this.onDemand}/admin/addFaq`;
  editFaq: string = `${this.onDemand}/admin/editFaq`;
  getFaq: string = `${this.onDemand}/admin/getAllFaq`;

  //  CMS - CRM
  addCrm: string = `${this.onDemand}/admin/addCrm`;
  getCrm: string = `${this.onDemand}/admin/getCrm`;

  //Catering
  getDataType: string = `${this.onDemand}/admin/dataType`;
  addTemplate: string = `${this.onDemand}/admin/templateField`;
  getTemplate: string = `${this.onDemand}/admin/templateField`;
  updateTemplate: string = `${this.onDemand}/admin/templateField`;
  deleteTemplate: string = `${this.onDemand}/admin/templateField`;
  getTemplateById: string = `${this.onDemand}/admin/templateFieldById`;

  // PromoCodes (Deals and Coupans)
  promoCode: string = `${this.onDemand}/admin/promocode`;
  deletePromoCode: string = `${this.onDemand}/admin/deletePromoCode`;

  // Taxi
  getRevenueTaxi: string = `${this.onDemand}/admin/revenue/taxi`;

  // Membership Plans
  addMembershipPlan: string = `${this.onDemand}/admin/addMembershipPlan`;
  addUserMembershipPlan: string = `${this.onDemand}/admin/addUserMembershipPlan`;
  getAllUserMembershipPlan: string = `${this.onDemand}/admin/getAllUserMembershipPlan`;
  deleteMembershipPlan: string = `${this.onDemand}/admin/deleteUserMembershipPlan`;
  getUserMemberShipbyid: string = `${this.onDemand}/admin/getUserMembershipPlanById`;
  updateUserMembership: string = `${this.onDemand}/admin/updateUserMembershipPlan`;

  getAllMembershipPlan: string = `${this.onDemand}/admin/getAllMembershipPlan`;
  getMembershipPlanById: string = `${this.onDemand}/admin/getMembershipPlanById`;
  editMembershipPlan: string = `${this.onDemand}/admin/editMembershipPlan`;

  // Vehical Types
  vehicalTypes: string = `${this.onDemand}/admin/vehicletype`;
  deleteVehicleType: string = `${this.onDemand}/admin/driver/deleteVehicleType`;
  driverVehicalTypes: string = `${this.onDemand}/driver/vehicletypes`;

  // Driver
  driver: string = `${this.onDemand}/admin/driver/detail`;
  addDriver: string = `${this.onDemand}/driver/register`;
  getDriverList: string = `${this.onDemand}/admin/driver/all`;
  getDriverOrderCount: string = `${this.onDemand}/admin/driver/getDriverOrderCount`;
  getDriverRatings: string = `${this.onDemand}/admin/driver/getDriversRatings`;
  updateDriverStatus: string = `${this.onDemand}/admin/driver/blockUnblockDriver`;
  driverDocument: string = `${this.onDemand}/admin/driver/document`;
  verifydocument: string = `${this.onDemand}/admin/driver/verifydocument`;
  driverByStatus: string = `${this.onDemand}/admin/driver/driverByStatus`;
  getAllVerticleTypes: string = `${this.onDemand}/driver/vehicletypes`;
  getAllDriverCSV: string = `${this.onDemand}/admin/getAllDriverCSV`;

  // Driver (using driver panel base url)
  getDriverToken: string = `${this.onDemand}/admin/driverToken`;

  // Admin Documents
  getDocs: string = `${this.onDemand}/admin/document`;

  // Comapny
  addCompany: string = `${this.onDemand}/company/addCompany`;
  getCompany: string = `${this.onDemand}/company/getCompany`;
  getCompanyById: string = `${this.onDemand}/company/getCompanyById`;
  updateCompany: string = `${this.onDemand}/company/updateCompany`;
  getStoreInventoryAll: string = `${this.onDemand}/company/updateCompany`;

  // Customer Support
  addCustomerSupport: string = `${this.onDemand}/user/customerSupport`;
  customerSupport: string = `${this.onDemand}/admin/customerSupport`;
  customerSupportReason: string = `${this.onDemand}/admin/customerSupportReason`;

  // Gift Card
  addGift: string = `${this.onDemand}/admin/gift/addGift`;
  getAllGift: string = `${this.onDemand}/admin/gift/getAllGift`;
  updateGift: string = `${this.onDemand}/admin/gift/updateGift`;

  // Language Management --
  addLanguage: string = `${this.onDemand}/admin/addLanguage`;
  getAllLanguage: string = `${this.onDemand}/admin/getAllLanguage`;
  editLanguage: string = `${this.onDemand}/admin/editLanguage`;
  deleteLanguage: string = `${this.onDemand}/admin/deleteLanguage`;

  // Sales Report
  itemWiseSalesPerAreaReport: string = `${this.comm}/admin/itemWiseSalesPerAreaReport`;

  // Broadcast Notification
  broadcastNotification: string = `${this.onDemand}/admin/broadcast`;
  getAllBroadCast: string = `${this.onDemand}/admin/getAllBroadCast`;
  getBroadCastById: string = `${this.onDemand}/admin/getBroadCastById`;

  // Payments
  getAllSalesPersonStores: string = `${this.onDemand}/admin/store/getAllSalesPersonStores`;
  getDriverTotalCommsion: string = `${this.onDemand}/admin/driver/getAllDriversTotalCommission`;
  getStoreTotalCommsion: string = `${this.onDemand}/admin/store/getAllMerchantTotalCommission`;
  getPaymentHistory: string = `${this.onDemand}/admin/payHistory`;
  payStore: string = `${this.onDemand}/admin/store/PAY`;

  // Sales Person
  subAdmin: string = `${this.onDemand}/admin/subAdmin`;
  addSalesPerson: string = `${this.onDemand}/admin/createSalesPerson`;
  deleteSalesPerson: string = `${this.onDemand}/admin/deleteSalesPerson`;
  getsalespersonbyid: string = `${this.onDemand}/admin/store/getsalespersonbyid`;
  updateSalesPerson: string = `${this.onDemand}/admin/store/editsalespersonbyId`;
  getSalesPersonList: string = `${this.onDemand}/admin/store/getAllSalesPerson`;
  getSubAdminById: string = `${this.onDemand}/admin/subAdminById`;

  // Admin Settings New Modules -- Start
  prefence: string = `${this.onDemand}/admin/prefence`;
  getAllReferal: string = `${this.onDemand}/admin/getAllReferal`;
  manageReferral: string = `${this.onDemand}/admin/manageReferral`;
  document: string = `${this.onDemand}/admin/document`;
  getSeo: string = `${this.onDemand}/admin/getSEO`;
  manageSeo: string = `${this.onDemand}/admin/manageSeo`;
  getCancellation: string = `${this.onDemand}/admin/getCancellation`;
  manageCancellation: string = `${this.onDemand}/admin/addCancellation`;
  deleteCancellation: string = `${this.onDemand}/admin/deleteCancellation`;
  manageCustomerSetting: string = `${this.onDemand}/admin/customerSettingMang`;
  getCustomerSetting: string = `${this.onDemand}/admin/getCustomerSetting`;
  manageNotification: string = `${this.onDemand}/admin/manageNotification`;
  getNotification: string = `${this.comm}/admin/getNotification`;
  countryJsonThirdParty: `https://openexchangerates.org/api/currencies.json`;
  //ThirdParty
  // getTimeZone:`https://worldtimeapi.org/api/timezone`;

  // Subscribe To Notification
  subscribeToNotification: string = `${this.onDemand}/store/subscribe`;

  // Assign Order
  AssignOrder: string = `${this.onDemand}/admin/freeDriverList`;
  assignDriverManually: string = `${this.onDemand}/admin/assignDriverManually`;

  // Warehouse
  warehouse: string = `${this.onDemand}/admin/warehouse`;
  getAllWarehouse: string = `${this.onDemand}/admin/getAllWarehouse`;
  assignOrderToWareHouse: string = `${this.onDemand}/admin/assignOrdersToWarehouse`;

  // Card releted Api
  addCard: string = `${this.onDemand}/admin/addCard`;
  getCard: string = `${this.onDemand}/admin/getCard`;
  deleteCard: string = `${this.onDemand}/admin/deleteCard`;

  // plan
  getAllPlans: string = `${this.onDemand}/admin/getAllPlans?`;
  subscribe: string = `${this.onDemand}/admin/subscribe`;
}
