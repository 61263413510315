<h2 mat-dialog-title *ngIf="item">Edit Vehicle Type</h2>
<h2 mat-dialog-title *ngIf="!item">Add Vehicle Type</h2>
<mat-dialog-content class="lst_bdyscl">
  <form [formGroup]="vehicleForm">
    <div class="usr_profile_inrmain">
      <div class="col-md-12 mb-2">
        <div class="row">
          <div class="col-12 form cmn_frm">
            <div class="usr_profile_biodata">
              <label>Name</label>
              <span class="red-str">*</span>
              <div class="input-group">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  formControlName="name"
                  placeholder="Enter Vehicle Type Name"
                  appNoSpaceAtFirst
                />
              </div>
              <div
                *ngIf="submitted && vehicleForm.controls.name.errors"
                class="invalid-feedback"
              >
                <div *ngIf="vehicleForm.controls.name.errors.required">
                  Name is required.
                </div>
                <div *ngIf="vehicleForm.controls.name.errors.maxlength">
                  Name can be max 35 characters long.
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 cmn_frm" *ngIf="greatSettings.geofancing">
            <label>Zone</label>
            <div class="input-group">
              <mat-select
                class="form-control order-module-select"
                placeholder="Select Zone"
                [(ngModel)]="zone"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="changeZone($event.value)"
              >
                <mat-option *ngFor="let item of zoneList" [value]="item._id">
                  {{ item.countryName }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-md-6 cmn_frm" *ngIf="greatSettings.geofancing">
            <label>Geofence</label>
            <div class="input-group">
              <mat-select
                class="form-control order-module-select"
                formControlName="geofenceId"
                placeholder="Select Geofence"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="changeGeofence($event.value)"
              >
                <mat-option
                  *ngFor="let item of geofenceList"
                  [value]="item._id"
                >
                  {{ item.geofenceName }}
                </mat-option>
              </mat-select>
            </div>
            <div
              *ngIf="submitted && vehicleForm.controls.geofenceId.errors"
              class="invalid-feedback"
            >
              <div *ngIf="vehicleForm.controls.geofenceId.errors.required">
                geofence is required.
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="form-group col-md-12">
        <p class="image_hding">
          Image
          <span class="red-str">*</span>
        </p>
        <div class="outer_uploadsshdf bigholderhor default_viewer">
          <div *ngIf="vehicleImage" class="image_placeholder">
            <figure>
              <img [src]="vehicleImage" alt="" />
            </figure>
          </div>

          <div class="image_placeholder_showup_file">
            <div class="dftlp" *ngIf="!vehicleImage">
              <img src="assets/img/upload_new.png" alt="choose_img" />
              <!-- <span>Upload File</span> -->
            </div>
            <input
              type="file"
              accept="image/*"
              (change)="fileHandler($event)"
            />
          </div>
        </div>
        <div class="invalid-feedback" *ngIf="submitted && !vehicleImage">
          Image is required.
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="botton_right_buttons btm-btn">
  <mat-dialog-actions>
    <button
      class="btn btn-primary mr-2 common-btn"
      *ngIf="!item"
      (click)="onSubmit()"
    >
      Add
    </button>
    <button
      class="btn btn-primary mr-2 common-btn"
      *ngIf="item"
      (click)="onUpdate()"
    >
      Update
    </button>
    <button class="btn btn-danger common-btn" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
