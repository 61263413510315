import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "src/app/core/services/common.service";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";

@Component({
  selector: "app-payment-history-modal",
  templateUrl: "./payment-history-modal.component.html",
  styleUrls: ["./payment-history-modal.component.scss"],
})
export class PaymentHistoryModalComponent implements OnInit {
  payee;
  typeOfPayee;
  appSettings;
  currentPage1: number = 1;
  config1: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  };
  paymentHistory: any;

  constructor(
    private dialog: MatDialogRef<PaymentHistoryModalComponent>,
    private api: HttpService,
    private comm: CommonService,
    private coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    this.appSettings = JSON.parse(localStorage.getItem("appSettings"));
    this.getPaymentHistory();
  }
  getPaymentHistory() {
    let params = `id=${this.payee._id}&type=${this.typeOfPayee}`;
    this.api
      .getWithQuery(this.coreUrlList.getPaymentHistory, params)
      .subscribe((res) => {
        if (res["response"]["success"]) {
          this.paymentHistory = res["data"];
        }
      });
  }

  close() {
    this.dialog.close("yes");
  }
}
