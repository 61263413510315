import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ForgotPasswordModalComponent } from "../forgot-password/forgot-password-modal.component";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(public dialog: MatDialog) {}

  isLoggedIn() {
    return this.getToken() !== null;
  }

  getToken() {
    return localStorage.getItem("token");
  }

  forgotPasswordsendEmail() {
    const dialogRef = this.dialog.open(ForgotPasswordModalComponent);
    return dialogRef.afterClosed();
  }
}
