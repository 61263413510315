import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/modules/auth/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if (state.url.includes("/auth/redirectLogin")) {
    //   console.log("1");

    //   let token = state.url.split("redirectLogin/")[1];
    //   localStorage.setItem("token", JSON.stringify(token));
    //   this.router.navigate(["/dashboard"]);
    // } else {
    //   return true;
    // }

    let fullUrl = window.location.hostname;
    console.log(state, "[]][[]");

    if (
      state.url == "/auth" &&
      (fullUrl == "localhost" ||
        fullUrl == "appdukaan" ||
        fullUrl.includes("prod."))
    ) {
      console.log("state.url>>>>>>>>>", state.url);
      if (
        localStorage.token == null ||
        localStorage.token == undefined ||
        localStorage.token == ""
      ) {
        this.router.navigate(["/auth/loginStatic"]);
        return true;
      } else {
        this.router.navigate(["/dashboard"]);
        return false;
      }
    }
    // else {
    //   if (
    //     localStorage.token == null ||
    //     localStorage.token == undefined ||
    //     localStorage.token == ""
    //   ) {
    //     this.router.navigate(["/auth/login"]);
    //   } else {
    //     return true;
    //   }
    // }
  }
}
