import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseUrl;
  driverBaseUrl: string;
  clientUrl: string;
  constructor(private _http: HttpClient, private comm: CommonService) {
    this.baseUrl = this.comm.getBaseUrl();
    // this.driverBaseUrl = "https://api.webdevelopmentsolution.net/driver/v1";
    // this.clientUrl = "https://prod.webdevelopmentsolution.net:6029";
  }

  getWithFetch(url: string) {
    return fetch(url).then((data) => {
      return data.json;
    });
  }

  // Post Service
  post(url: string, postData: any) {
    return this._http.post<any>(`${this.baseUrl}${url}`, postData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  postForClient(url: string, postData: any) {
    return this._http.post<any>(`${this.clientUrl}${url}`, postData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post With Params Service
  postWithParams(url: string, id, postData: any) {
    return this._http.post<any>(`${this.baseUrl}${url}/${id}`, postData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Post With Query Service
  postWithQuery(url: string, params, postData: any) {
    return this._http
      .post<any>(`${this.baseUrl}${url}?${params}`, postData)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  // Get Service
  get(url: string) {
    return this._http.get<any>(`${this.baseUrl}${url}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getForClient(url: string) {
    return this._http.get<any>(`${this.clientUrl}${url}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get With Query
  getWithQuery(url: string, params) {
    return this._http.get<any>(`${this.baseUrl}${url}?${params}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get With Param and Query
  getWithParmas(url: string, id) {
    return this._http.get<any>(`${this.baseUrl}${url}/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get With Param and Query
  getWithQueryParmas(url: string, id, params) {
    return this._http.get<any>(`${this.baseUrl}${url}/${id}?${params}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Put Service
  put(url: string, putData: any) {
    return this._http.put<any>(`${this.baseUrl}${url}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  putWithClient(url: string, putData: any) {
    return this._http.put<any>(`${this.clientUrl}${url}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Put Service With Query
  putWithQuery(url: string, params, putData: any) {
    return this._http.put<any>(`${this.baseUrl}${url}?${params}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Put With Query Param Service
  putWithQueryParam(url: string, id: string, putData: any) {
    return this._http.put<any>(`${this.baseUrl}${url}/${id}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Patch Service
  patch(url: string, putData: any) {
    return this._http.patch<any>(`${this.baseUrl}${url}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete Service
  delete(url: string, params?) {
    return this._http.delete<any>(`${this.baseUrl}${url}`, params).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete With Query Service
  deleteWithQuery(url: string, params?) {
    return this._http.delete<any>(`${this.baseUrl}${url}?${params}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete With Query Param
  deleteWithQueryParam(url: string, id) {
    return this._http.delete<any>(`${this.baseUrl}${url}/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Upload Image Service
  uploadMedia(url, formData) {
    return this._http.post<any>(`${this.baseUrl}` + url, formData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getDriverToken(url: string) {
    return this._http.get<any>(`${this.driverBaseUrl}${url}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  

}
