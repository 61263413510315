import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { appNoSpaceAtFirst } from "./directives/no-space.directive";
import { NumberDotOnlyDirective } from "./directives/number-dot-only.directive";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { TextOnlyDirective } from "./directives/only-text.directive";

import { RequiredDirective } from "./directives/required.directive";
import { TwoDigitDecimaNumberDirective } from "./directives/two-decimal-point.directive";
import { ImgaeFilterPipe } from "./pipes/imgae-filter.pipe";
import { ReplaceTagDirective } from "./directives/replace-tag.directive";
import { TextWithSpaceDirective } from "./directives/text-with-space.diractive";
import { DecimalNumberDirective } from "./directives/decimal-number.directive";
import { decimalDigest } from "@angular/compiler/src/i18n/digest";

@NgModule({
  declarations: [
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
    NumberOnlyDirective,
    TextOnlyDirective,
    RequiredDirective,
    TwoDigitDecimaNumberDirective,
    ImgaeFilterPipe,
    ReplaceTagDirective,
    TextWithSpaceDirective,
    DecimalNumberDirective,
  ],
  exports: [
    ImgaeFilterPipe,
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
    NumberOnlyDirective,
    TextOnlyDirective,
    RequiredDirective,
    TwoDigitDecimaNumberDirective,
    TextWithSpaceDirective,
    DecimalNumberDirective,
  ],
  imports: [CommonModule],
})
export class CoreModule {}
