import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "../../../../core/services/common.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";

@Component({
  selector: "app-add-vehicle-modal",
  templateUrl: "./add-vehicle-modal.component.html",
  styleUrls: ["./add-vehicle-modal.component.scss"],
})
export class AddVehicleModalComponent implements OnInit {
  vehicleForm: FormGroup;
  vehicleImage: any;
  File;
  submitted: boolean = false;
  errorImage: boolean = false;
  item: any;
  // seatType: any = [
  //   "Type 1 –14 Seater", "Type 2 –10 Seater (Shuttle)", "Type 3 –29 Seater (Mini Bus)", "Type 4 –33 Seater (Mini Bus)", "Type 5 –49 Seater Bus",
  //   "Type 6 –45 Seater Bus with VIP", "Type 7 –36 Seater Bus with VIP", "Type 8 –62 Seater Bus"
  // ]
  type: any = [
    {
      name: "Food and Grocery Delivery",
      value: 1,
    },
    {
      name: "Taxi",
      value: 2,
    },
    {
      name: "Shuttle",
      value: 3,
    },
    {
      name: "All",
      value: 4,
    },
  ];
  moduleKey: any;
  geofence: any;
  geofenceList: any;
  zone: any;
  zoneList: any;
  greatSettings: any;
  showDeleteIcon: boolean = false;
  constructor(
    private dialog: MatDialogRef<AddVehicleModalComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public toastr: ToastrManager,
    public route: ActivatedRoute,
    public api: HttpService,
    public coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    this.greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    // this.zoneList = JSON.parse(localStorage.getItem("zoneList"));
    this.vehicleForm = this.formBuilder.group({
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(35),
      ]),
      seats: new FormControl("10", [Validators.required, Validators.min(1)]),
      // geofenceId: new FormControl("", [Validators.required]),
    });
    if (this.item) {
      this.setValues();
      // this.deleteImage(this.item);
    }
  }
  // getZones() {
  //   this.zone = this.zoneList["0"]["_id"];
  //   this.getGeoFences(this.zone);
  // }
  // changeZone(value) {
  //   this.zone = value;
  //   this.getGeoFences(value);
  // }
  // getGeoFences(zoneId) {
  //   this.api
  //     .getWithParmas(this.coreUrlList.getGeofence, zoneId)
  //     .subscribe((res) => {
  //       if (res["response"]["success"]) {
  //         this.geofenceList = res["data"];
  //       }
  //     });
  // }
  // changeGeofence(value) {
  //   this.geofence = value;
  // }
  async uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    let res = await this.api
      .post(this.coreUrlList.uploadFile, formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.vehicleImage = event.target.result;
        };
      }
      {
        this.errorImage = false;
        this.File = event.target.files[0];
        this.vehicleImage = await this.uploadImage(File);
        this.showDeleteIcon = true;
      }
    } else {
      this.errorImage = true;
      this.toastr.errorToastr("Only PNG, JPEG ,JPG are Allow ");
    }
  }

  setValues = () => {
    if (this.item) {
      this.vehicleForm.patchValue({
        name: this.item.name,
        // geofenceId: this.item.geofenceId,
      });
      if (this.item.image.charAt(0) == "/") {
        this.vehicleImage = this.comm.imageUrl + this.item.image;
      } else {
        this.vehicleImage = this.item.image;
        if (this.vehicleImage) {
          this.showDeleteIcon = true;
        }
      }
    }
  };

  onSubmit() {
    this.submitted = true;
    if (this.File) {
      console.log(this.File);

      console.log(!this.errorImage);

      if (this.vehicleForm.valid && this.submitted && !this.errorImage) {
        var data = {
          name: this.vehicleForm.controls["name"].value.trim(),
          // geofenceId: this.vehicleForm.controls["geofenceId"].value.trim(),

          moduleKey: this.moduleKey,
          image: this.vehicleImage,
        };
        data["verticalType"] = this.comm.getVerticalTypeByModuleKey(
          this.moduleKey
        );
        // let formData = new FormData();
        // formData.append("data", JSON.stringify(data));
        // formData.append("image", this.File);

        if (data["image"] == "") {
          delete data["image"];
        }
        this.api.post(this.coreUrlList.vehicalTypes, data).subscribe((res) => {
          if (res["response"]["success"]) {
            this.comm.successToast(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.dialog.close("no");
          }
        });
      }
    }
  }

  close() {
    this.dialog.close();
  }

  onUpdate() {
    this.submitted = true;
    if (this.File || this.item.image) {
      if (this.vehicleForm.valid && this.submitted && !this.errorImage) {
        var data = {
          name: this.vehicleForm.controls["name"].value.trim(),
          // geofenceId: this.vehicleForm.controls["geofenceId"].value.trim(),

          moduleKey: this.moduleKey,
          updateId: this.item._id,
          image: this.vehicleImage,
        };
        data["verticalType"] = this.comm.getVerticalTypeByModuleKey(
          this.moduleKey
        );
        // let formData = new FormData();
        // formData.append("data", JSON.stringify(data));
        // if (this.File) {
        //   formData.append("image", this.File);
        // } else {
        //   formData.append("image", this.item.image);
        // }
        if (data["image"] == "") {
          delete data["image"];
        }
        this.api.put(this.coreUrlList.vehicalTypes, data).subscribe((res) => {
          if (res["response"]["success"]) {
            this.comm.successToast(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.dialog.close("no");
          }
        });
      }
    }
  }
}
