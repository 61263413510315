import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },

  {
    path: "",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "auth",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "publicLink",
    loadChildren: () =>
      import("./modules/public-links/public-modules.module").then(
        (m) => m.PublicModulesModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
